<template>
  <div class="d-flex w-100">
    <div class="w-100 d-flex flex-column">
      <base-form
        deleteBtn
        :loading="loading"
        :statut="statut"
        @click="updateContrat(contrat)"
        @delete="deleteContrat(contrat)"
      >
        <!--<BaseToolBar
          back
          subtitle
          :backRoute="{ name: 'sirh_collaborateur_contrats', params: { id: collaborateur_id }}"
          :title="'Contrat n°'+ contrat.id"
        >
          <BaseIcon
            name="circle"
            width="30"
            height="30"
            class="text-success m-auto"
            fill="currentColor"
            v-if="contrat.contrat_actif"
            v-tooltip="'Contrat actif'"
          ></BaseIcon>
          <BaseIcon
            name="circle"
            width="30"
            height="30"
            class="text-danger m-auto"
            fill="currentColor"
            v-if="!contrat.contrat_actif"
            v-tooltip="'Contrat inactif'"
          ></BaseIcon>
        </BaseToolBar>-->

        <div class="container-fluid">
          <div class="pb-3 row flex-nowrap overflow-auto">
            <ContratFormTab
              icon="log-in"
              title="Proposition d'embauche"
              :active="tabView === 'prop_embauche'"
              @click="tabView = 'prop_embauche'"
            ></ContratFormTab>
            <ContratFormTab
              icon="calendar"
              title="Période d'essais"
              :active="tabView === 'periode_essais'"
              @click="tabView = 'periode_essais'"
            ></ContratFormTab>
            <ContratFormTab
              icon="info"
              title="Contrat de travail"
              @click="tabView = 'infos'"
              :active="tabView === 'infos'"
            ></ContratFormTab>
            <ContratFormTab
              icon="log-out"
              title="Rupture contrat"
              @click="tabView = 'rupture'"
              :active="tabView === 'rupture'"
            ></ContratFormTab>
          </div>
        </div>

        <base-form-group title="Proposition d'embauche" dense v-show="tabView === 'prop_embauche'">
          <base-form-row>
            <BaseInputDatePicker
              class
              v-model="contrat.contrat_prop_date_envois"
              inputText="Date d'envois"
              :errors="feedback.contrat_prop_date_envois"
              :iconActive="true"
            ></BaseInputDatePicker>
            <BaseInputDatePicker
              class
              v-model="contrat.contrat_prop_date_retour"
              inputText="Date de retour"
              :errors="feedback.contrat_prop_date_retour"
              :iconActive="true"
            ></BaseInputDatePicker>
          </base-form-row>
          <div class="mt-0">
            <BaseSelect
              class="pt-3"
              inputText="N°Siret (proposition)"
              v-model.number="contrat.contrat_prop_siret_id"
              :options="data.etablissements"
            ></BaseSelect>
            <BaseInput
              class="pt-3"
              v-model="contrat.contrat_prop_reponse"
              inputText="Réponse"
              :errors="feedback.contrat_prop_reponse"
              :iconActive="true"
            ></BaseInput>
          </div>
        </base-form-group>
        <base-form-group title="Période d'essais" dense v-show="tabView === 'periode_essais'">
          <base-form-row>
            <BaseInput
              class
              v-model="contrat.contrat_pe_duree"
              inputText="Durée (mois)"
              :errors="feedback.contrat_pe_duree"
              :iconActive="true"
            ></BaseInput>
            <BaseInputDatePicker
              class
              v-model="contrat.contrat_pe_date_fin"
              inputText="Date de fin"
              :errors="feedback.contrat_pe_date_fin"
              :iconActive="true"
            ></BaseInputDatePicker>
          </base-form-row>
          <base-form-row>
            <BaseSelect
              class
              inputText="Etat"
              v-model.number="contrat.contrat_pe_rupture_id"
              :options="data.peRuptures"
            ></BaseSelect>
            <BaseInputDatePicker
              class
              v-model="contrat.contrat_pe_renouvellement"
              inputText="Date de renouvellement"
              :errors="feedback.contrat_pe_renouvellement"
              :iconActive="true"
            ></BaseInputDatePicker>
          </base-form-row>
          <base-form-row>
            <BaseInputDatePicker
              class
              v-model="contrat.contrat_pe_date_courrier"
              inputText="Date courrier"
              :errors="feedback.contrat_pe_date_courrier"
              :iconActive="true"
            ></BaseInputDatePicker>
          </base-form-row>
        </base-form-group>
        <base-form-group title="Informations" dense v-show="tabView === 'infos'">
          <base-form-row>
            <BaseInputDatePicker
              class
              v-model="contrat.contrat_date_debut"
              inputText="Date de début"
              :errors="feedback.contrat_date_debut"
              :iconActive="true"
            ></BaseInputDatePicker>
            <BaseInputDatePicker
              class
              v-model="contrat.contrat_date_fin"
              inputText="Date de fin"
              :errors="feedback.contrat_date_fin"
              :iconActive="true"
            ></BaseInputDatePicker>
          </base-form-row>
        </base-form-group>
        <base-form-group title="Paramètres" v-show="tabView === 'infos'">
          <div class="w-100 d-flex mt-3">
            <ContratParams :contrat="contrat"></ContratParams>
          </div>
        </base-form-group>
        <base-form-group title="Rupture" dense v-show="tabView === 'rupture'">
          <base-form-row>
            <BaseSelect
              class
              inputText="Rupture"
              v-model.number="contrat.contrat_rupture_id"
              :options="data.contratRuptures"
            ></BaseSelect>
            <BaseInput
              class
              v-model="contrat.contrat_rupture_commentaire"
              inputText="Rupture commentaire"
              :errors="feedback.contrat_rupture_commentaire"
              :iconActive="true"
            ></BaseInput>
          </base-form-row>
        </base-form-group>
      </base-form>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import { errHandler } from "@/helpers.js";

import BaseInput from "@/components/bases/Input.vue";
import BaseInputDatePicker from "@/components/bases/InputDatePicker.vue";
import BaseSelect from "@/components/bases/Select.vue";
//import BaseIcon from "@/components/bases/Icon.vue";
import BaseForm from "@/components/bases/Form.vue";
import BaseFormGroup from "@/components/bases/FormGroup.vue";
import BaseFormRow from "@/components/bases/FormRow.vue";
//import BaseToolBar from "@/components/bases/ToolBar.vue";
import ContratFormTab from "@/components/collaborateurs/profil/forms/contrat/ContratFormTab.vue";
import ContratParams from "@/components/collaborateurs/profil/forms/contrat/ContratParams.vue";

export default {
  components: {
    BaseInput,
    BaseSelect,
    BaseInputDatePicker,
    //BaseIcon,
    BaseForm,
    //BaseToolBar,
    BaseFormGroup,
    BaseFormRow,
    ContratFormTab,
    ContratParams
  },
  data() {
    return {
      feedback: {},
      loading: false,
      tabView: "infos",
      statut: null
    };
  },
  props: ["contrat"],
  computed: {
    ...mapGetters({
      data: "collaborateurs/data"
    })
  },
  methods: {
    ...mapActions({
      _updateContrat: "collaborateurs/updateContrat",
      _deleteContrat: "collaborateurs/deleteContrat"
    }),
    updateContrat: function(contrat) {
      this.feedback = {};
      this.loading = true;
      this._updateContrat(contrat)
        .catch(error => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    deleteContrat: function(contrat) {
      if (!window.confirm("Confirmer la suppression de l'élément ?")) return null
      this.feedback = {};
      this.loading = true;
      this._deleteContrat(contrat)
        .catch(error => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    consoleContrat: function() {
      console.log("contrat", this.contrat);
    }
  }
};
</script>
<style lang="css"></style>
